<template>
  <div>
    <div :style="{ opacity: headerStoryLoaded ? '1' : '0' }">
      <HeaderDefault
        v-if="
          headerStorySettings?.isHeaderNavActive ||
          headerDefaultSettings?.content?.isHeaderNavActive
        "
        :blok="
          headerStorySettings?.isHeaderNavActive
            ? headerStorySettings
            : headerDefaultSettings?.content
        "
      />
    </div>
    <slot />
    <WPFooter
      v-if="footerStorySettings && footerStorySettings.footerLinks"
      :blok="footerStorySettings"
    />
    <FooterDefault v-else-if="!footerStorySettings.footerLinks" :blok="footerStorySettings" />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'

const headerStorySettings = ref({})
const footerStorySettings = ref({})
const headerStoryLoaded = ref(false)

const { setFullSlug, defaultHeaderPath, defaultFooterPath } = useDefaultSettingsPath()
const { storySettings, defaultSettings } = await useStoryDefaultSettings()
const story = useStoryData()
const { storyData } = storeToRefs(story)
const { pageTrackingData } = useGaTrackingStore()
const tracking = useTracking()

setFullSlug(storySettings.value)

const { value: headerDefaultSettings } = await defaultSettings(defaultHeaderPath.value)
const { value: footerDefaultSettings } = await defaultSettings(defaultFooterPath.value)

onMounted(() => {
  headerStoryLoaded.value = true

  Object.assign(headerStorySettings.value, {
    isHeaderNavActive:
      storyData.value?.content.isHeaderNavActive ||
      headerDefaultSettings?.content.isHeaderNavActive,
    navLinks: headerDefaultSettings?.content.navLinks.concat(storyData.value?.content.navLinks),
    headerLogo:
      storyData.value?.content?.Logo?.length > 0
        ? [storyData.value?.content.Logo[0]]
        : headerDefaultSettings?.content.headerLogo,
    specialNavLinks: headerDefaultSettings?.content.specialNavLinks.concat(
      storyData.value?.content.specialNavLinks
    ),
    cta: storyData.value.content.cta
  })

  Object.assign(footerStorySettings.value, {
    disclaimerLinks: footerDefaultSettings?.content.disclaimerLinks,
    footerLinks: footerDefaultSettings?.content.footerLinks?.concat(
      storyData.value?.content.footerLinks
    ),
    footerAddLinks: footerDefaultSettings?.content.footerAddLinks,
    isTrustpilotFooterHidden:
      storyData.value?.content.isTrustpilotFooterHidden ??
      footerDefaultSettings?.content.isTrustpilotFooterHidden,
    isTuevFooterHidden:
      storyData.value?.content.isTuevFooterHidden ??
      footerDefaultSettings?.content.isTuevFooterHidden
  })

  trackPageData()
})

watch(storyData?.value, async (newValue) => {
  setFullSlug(newValue)
  const { value: headerDefaultSettings } = await defaultSettings(defaultHeaderPath.value)

  Object.assign(headerStorySettings.value, {
    isHeaderNavActive:
      newValue.content.isHeaderNavActive || headerDefaultSettings?.content.isHeaderNavActive,
    navLinks: headerDefaultSettings?.content.navLinks.concat(newValue.content.navLinks),
    headerLogo:
      newValue.content.Logo?.length > 0
        ? [newValue.content.Logo[0]]
        : headerDefaultSettings?.content.headerLogo,
    specialNavLinks: headerDefaultSettings?.content.specialNavLinks.concat(
      newValue.content.specialNavLinks
    ),
    cta: newValue.content.cta
  })

  trackPageData()
})

watch(storyData?.value, async (newValue) => {
  setFullSlug(newValue)
  const { value: footerDefaultSettings } = await defaultSettings(defaultFooterPath.value)

  Object.assign(footerStorySettings.value, {
    footerLinks: footerDefaultSettings?.content.footerLinks?.concat(newValue.content.footerLinks),
    footerAddLinks: footerDefaultSettings?.content.footerAddLinks,
    isTrustpilotFooterHidden: newValue.content.isTrustpilotFooterHidden,
    isTuevFooterHidden: newValue.content.isTuevFooterHidden
  })
})

const trackPageData = () => {
  pageTrackingData.value = {
    content_group:
      storyData.value?.content.ga_content_group?.length > 1
        ? storyData.value?.content.ga_content_group
        : 'unset',
    content_type:
      storyData.value?.content.ga_content_type?.length > 1
        ? storyData.value?.content.ga_content_type
        : 'unset',
    content_name:
      storyData.value?.content.ga_content_name?.length > 1
        ? storyData.value?.content.ga_content_name
        : 'unset',
    content_id:
      storyData.value?.content.ga_content_id?.length > 1
        ? storyData.value?.content.ga_content_id
        : 'unset'
  }

  tracking.trackDatalayer(toRaw(pageTrackingData.value))
}
</script>
